import React, { useEffect, useState } from "react";
import { useColorMode, VStack, Text, Box, Skeleton, Flex } from "@chakra-ui/react";
import { useLazyGetQuestionsQuery } from "../../api/questions";
import { motion } from "framer-motion";
import QuestionCard from "./QuestionCard";
import { Answer, Question, User } from "../../types/interface";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectIsAuthenticated } from "../../slice/userSlice";
import AutoBackButton from "../AutoBackButton";
import { Helmet } from "react-helmet";
import SearchInput from "../Search/SearchInput";

const Questions: React.FC = () => {
  const { colorMode } = useColorMode();
  const [trigger, { data: questionsData, isLoading }] = useLazyGetQuestionsQuery({});
  const [questions, setQuestions] = useState<Question[]>([]);
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = userId || currentUser?.id;
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setIsLoading] = useState(false);

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    try {
      setSearchQuery(query);
      // Implement your search logic here if needed
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    trigger({}, true); // Trigger the query, prefer cached value if available
  }, [trigger]);

  useEffect(() => {
    if (questionsData) {
      // Sort questions by date from newest to oldest before setting state
      const sortedQuestions = [...questionsData.data].sort(
        (a: Question, b: Question) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setQuestions(sortedQuestions);
    }
  }, [questionsData]);

  const updatedAnswersData = (questionId: string, updatedAnswers: Answer[]) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q._id === questionId ? { ...q, answers: updatedAnswers } : q
      )
    );
  };

  const viewAnswers = (questionId: string) => {
    console.log(`Clicked on question with ID: ${questionId}`);
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      return navigate("/login");
    }
    
    navigate(`/user/${validUserId}/posts/${questionId}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
        width: '100%', // Ensure full width
        minHeight: '100vh', // Optional: ensure it takes full viewport height
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ewé - Questions Page</title>
      </Helmet>
      <Box
        paddingTop="20px"
        paddingBottom="20px"
        marginX="auto" // Center horizontally with auto margins
        maxWidth="100%" // Ensure full width
        // Removed marginRight and marginLeft
      >
        <AutoBackButton />
        <Box
          padding={4}
          display={'flex'}
          flexDirection={{ base: 'column' }}
          justifyContent="space-between"
          alignItems="center"
          gap={10}
          width="100%" // Ensure it takes full width
        >
          <SearchInput
            backgroundColor="#E0EAE0"
            query={searchQuery}
            updateQuery={(query) => handleSearch(query)}
            isLoading={loading}
            onSubmit={handleSearch}
          />
        </Box>
        <div style={{ overflowY: 'auto' }}>
        <VStack 
          // width="100%" // Ensure VStack takes full width
          backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
          borderRadius="20px"
          spacing={4}
          padding={5} // Reduced padding from 20 to 4 for better layout
          m={5}
        >
          <Box width="100%" overflowY="auto">
            {isLoading ? (
              <Box textAlign="center" p={10}>
                <Skeleton height="500px" borderRadius={20} color={"transparent"} />
              </Box>
            ) : (
              questions.map((question) => (
                <Box key={question._id} width="100%">
                  <QuestionCard
                    question={question}
                    onQuestionClick={viewAnswers}
                    updatedAnswersData={updatedAnswersData}
                  />
                </Box>
              ))
            )}
            {!isLoading && questions.length === 0 && (
              <Text>No questions available.</Text>
            )}
          </Box>
        </VStack>
        </div>
      </Box>
    </motion.div>
  );
};

export default Questions;
