import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../types/interface";
import { createSelector } from "@reduxjs/toolkit";
import { selectAccessToken } from "../slice/userSlice";
import { questionsAdapter } from "../slice/question";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = questionsAdapter.getInitialState([]);

export const questionsApiSlice = createApi({
  reducerPath: 'questions',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState | undefined;
      const accessToken = state ? selectAccessToken(state) : '';
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');
    },
  }),
  
  tagTypes: ['Questions'],
  endpoints: (builder) => ({
    getQuestions: builder.query({
      query: () => `/questions`,
      providesTags: ['Questions'],
    }),
    getQuestionById: builder.query({
      query: (questionId) => ({
        url: `/questions/${questionId}`,
        invalidatesTags: [{ type: "Questions", id: questionId }],
      }),
    }),
    createQuestion: builder.mutation({
      query: ({ userId, body }) => ({
        url: `users/${userId}/questions`,
        method: 'POST',
        body: body,
        invalidatesTags: ['Questions'],
      }),
    }),
    updateQuestion: builder.mutation({
      query: ({ userId, questionId, body }) => ({
        url: `users/${userId}/questions/${questionId}`,
        method: 'PATCH',
        body: body,
        invalidatesTags: [{ type: "Questions", id: questionId }],
      }),
    }),
    deleteQuestion: builder.mutation({
      query: ({ userId, questionId }) => ({
        url: `users/${userId}/questions/${questionId}`,
        method: "DELETE",
        invalidatesTags: [{ type: "Questions", id: questionId }],
      }),
    }),
    likeAnswer: builder.mutation({
      query: ({ userId, questionId, answersId }) => ({
        url: `users/${userId}/questions/${questionId}/answers/${answersId}/like`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: "Questions" }],
    }),
    dislikeAnswer: builder.mutation({
      query: ({ userId, questionId, answersId  }) => ({
        url: `users/${userId}/questions/${questionId}/answers/${answersId}/dislike`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: "Questions"}],
    }),
    answerQuestion: builder.mutation({
      query: ({ userId, questionId, comment }) => ({
        url: `users/${userId}/questions/${questionId}/answers`,
        method: 'POST',
        body: comment,
      }),
      invalidatesTags: [{ type: "Questions"}],
    }),
   editQuestion: builder.mutation({
      query: ({ userId, questionId }) => ({
        url: `users/${userId}/questions/${questionId}`,
        method: "PATCH",
        invalidatesTags: [{ type: "Questions", id: questionId }],
      }),
    }),
  }),
});

export const { 
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery, 
  useGetQuestionByIdQuery,
  useCreateQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
useAnswerQuestionMutation,
useDislikeAnswerMutation,
useLikeAnswerMutation,
useEditQuestionMutation
} = questionsApiSlice;

const selectQuestionsResult = questionsApiSlice.endpoints.getQuestions.select('');

const selectQuestionsData = createSelector(
  selectQuestionsResult,
  (questionsResult) => questionsResult.data
);

export const {
  selectAll: selectAllQuestions,
  selectById: selectQuestionById,
  selectIds: selectQuestionIds,
} = questionsAdapter.getSelectors((state: RootState) =>
  selectQuestionsData(state.api) ?? initialState
);
