import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Box, Heading, Text } from '@chakra-ui/react';
import { ArticleForm, AutoBackButton } from '../components';
import { User } from '../types/interface';
import { selectCurrentUser } from '../slice/userSlice';
import { useSelector } from 'react-redux';

const CreateArticle = () => {
  const currentUser = useSelector(selectCurrentUser) as unknown as User
    const userRole = currentUser?.role

    // console.log("User Role: ", userRole);
    

  const canCreateArticle = () => {
    return userRole === 'SPECIALIST';
  };
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Create Publication</title>
        </Helmet>
  
        <Box
          paddingTop="20px"
          paddingBottom="20px"
          marginRight={{ base: '15', md: '20' }}
          marginLeft={{ base: '15', md: '20' }}
          fontFamily="Inter"
          height={'100%'}
        >
                      <AutoBackButton/>
          <Heading 
              fontFamily="Inter"
              as="h6"
              fontSize={{ base: '20px', md: "30px"}}
              textAlign="center">
            Create Publication
          </Heading>
          {canCreateArticle() ? (
          <ArticleForm />
        ) : (
          <Text
          textAlign={'center'}
          marginTop={20}
          marginBottom={20}
          >
            You are not authorized to create a Publication.
            You have to be a specialist to create Publication
          </Text>
        )}
        </Box>
      </motion.div>
    );
  };
  
  export default CreateArticle;
  