import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  SimpleGrid,
  Skeleton,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetAllPostsQuery } from "../../api/postApi";
import { useLazyGetQuestionsQuery } from "../../api/questions";
import HomePostContent from "./HomePostContent";
import QuestionCard from "../Questions/QuestionCard";
import { Post, Question, RootState, User } from "../../types/interface";
import { selectCurrentUser } from "../../slice/userSlice";
import { useSelector } from "react-redux";

const itemsPerPage = 6;

const HomeCollections: React.FC = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = userId || currentUser?.id;
  const language = useSelector((state: RootState) => state.language.language);
  // Fetching posts
  const [triggerPosts, { data: allPostsData, isLoading: allPostsLoading, isError: allPostsError }] = useLazyGetAllPostsQuery();
  const [allPosts, setAllPosts] = useState<Post[]>([]);

  // Fetching questions
  const [triggerQuestions, { data: questionsData, isLoading: questionsLoading, isError: questionsError }] = useLazyGetQuestionsQuery();
  const [questions, setQuestions] = useState<Question[]>([]);

  const [currentPage, setCurrentPage] = useState(1);


  const totalItems = (allPosts?.length || 0) + (questions?.length || 0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);


  
  useEffect(() => {
    triggerPosts({}, true);
    triggerQuestions({}, true);
  }, [triggerPosts, triggerQuestions]);

  useEffect(() => {
    if (allPostsData) {
      setAllPosts(allPostsData?.data?.posts);
    }
  }, [allPostsData]);

  useEffect(() => {
    if (questionsData) {
      setQuestions(questionsData.data);
    }
  }, [questionsData]);

  const updatePostData = (postId: string, newData: any) => {
    setAllPosts((prevPosts: any[]) =>
      prevPosts.map((post: any) =>
        post._id === postId ? { ...post, ...newData } : post
      )
    );
  };

  const updateQuestionData = (questionId: string, newData: any) => {
    setQuestions((prevQuestions: any[]) =>
      prevQuestions.map((question: any) =>
        question._id === questionId ? { ...question, ...newData } : question
      )
    );
  };

  const handlePostCardClick = (postId: string) => {
    navigate(`/user/${validUserId}/posts/${postId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


 // Get the posts for the current page first
const displayedPosts = allPosts.slice(
  (currentPage - 1) * itemsPerPage,
  Math.min(currentPage * itemsPerPage, allPosts.length)
);

// Display remaining questions for the current page
const displayedQuestions = questions.slice(
  (currentPage - 1) * itemsPerPage,
  Math.min(currentPage * itemsPerPage, questions.length)
);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{
        backgroundColor: colorMode === "light" ? "white" : "#345430",
        color: colorMode === "light" ? "white" : "#345430",
      }}
    >
      <Text
        fontSize={{ base: "20px", md: "30px", lg: "40px" }}
        fontWeight="600"
        fontFamily="Inter"
        letterSpacing={3}
        textAlign="center"
        color={colorMode === "light" ? "#345430" : "white"}
        paddingTop="40px"
        paddingBottom="20px"
      >
        {language === 'en' ? 'Posts & Questions' : language === 'yo' ? 'Iwadi ati Ìbéèrè' : 'Publicaciones & Preguntas'}
      </Text>
      <Box
        style={{
          backgroundColor: colorMode === "light" ? "#E0EAE0" : "#588552",
          borderRadius: "20px",
        }}
      >
        {allPostsError || questionsError ? (
          <Box
            textAlign="center"
            paddingTop="20px"
            fontSize="18px"
            fontWeight="bold"
          >
            Error loading content. Please try again.
          </Box>
        ) : (
          <Box
            paddingTop="20px"
            pb={20}
            color={colorMode === "light" ? "#345430" : "white"}
          >
    {allPostsLoading || questionsLoading ? (
              <Box textAlign="center" p={10}>
                <Skeleton height="500px" borderRadius={20} color={"transparent"} />
              </Box>
            ) : (
              <>
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  spacingX="40px"
                  spacingY="20px"
                  p={10}
                >
                  {displayedPosts.map((post) => (
                    <HomePostContent
                      key={post._id}
                      post={post}
                      handlePostCardClick={handlePostCardClick}
                      isLoading={allPostsLoading}
                      updatedPostData={updatePostData}
                    />
                  ))}
                {displayedQuestions.map((question) => (
                  <QuestionCard
                    key={question._id}
                    question={question}
                    onQuestionClick={() => handlePostCardClick(question._id)}
                    updatedAnswersData={updateQuestionData}
                  />
                ))}
                </SimpleGrid>

                <Box textAlign="center" p={10} mt="4">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <Button
                      key={index}
                      mx="1"
                      variant="ghost"
                      onClick={() => handlePageChange(index + 1)}
                      colorScheme={
                        currentPage === index + 1 ? "green" : undefined
                      }
                      backgroundColor={
                        currentPage === index + 1 ? "white" : undefined
                      }
                      marginBottom={"20px"}
                    >
                      {index + 1}
                    </Button>
                  ))}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

export default HomeCollections;
