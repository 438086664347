import React, { useEffect, useState } from 'react';
import { Box, useColorMode, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Articles, RootState, User } from '../../types/interface';
import { useSelector } from 'react-redux';
import { useLazyGetBookmarksQuery } from '../../api/postApi';
import { selectIsAuthenticated } from '../../slice/userSlice';
import Error from './components/BookmarkedError';
import Loading from './components/BookmarkLoading';
import BookmarkedPosts from './components/BookmarkedPosts';
import BookmarkedArticles from './components/BookmarkedArticles';
import { AutoBackButton } from '../../components';
import { useGetBookmarkArticleQuery } from '../../api/articles';

const Bookmarks = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.users.currentUser) as User;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [trigger, { data, isLoading: bookmarksLoading, isError: bookmarksError }] = useLazyGetBookmarksQuery();
  const { data: articles } = useGetBookmarkArticleQuery(currentUser.id);
  const [bookmarks, setBookmarks] = useState(data?.data?.bookmark || []); 
  const [bookmarkArticles, setBookmarkArticles] = useState(articles?.data || []); 
  const language = useSelector((state: RootState) => state.language.language);
  // console.log('Bookmarks Articles: ', articles); 
  // console.log('Bookmarks Posts: ', data); 

  useEffect(() => {
    if (currentUser.id) {
      trigger(currentUser.id, true); // Fetch post data, prefer cache if available
    }
  }, [currentUser.id, trigger]);

  useEffect(() => {
    if (data) {
      const bookmarkData = data?.data?.bookmark
      setBookmarks(bookmarkData);
    }
  }, [data]);


  useEffect(() => {
    if (articles) {
      const bookmark = articles?.data
      setBookmarkArticles(bookmark);
    }
  }, [articles]);

  const handlePostCardClick = (postId: string) => {
    navigate(`/user/${currentUser.id}/posts/${postId}`);
  };

  const updatePostData = (postId: string, newData: any) => {
    const updatedBookmarks = bookmarks.map((bookmark: any) =>
      bookmark.post._id === postId ? { ...bookmark, post: { ...bookmark.post, ...newData } } : bookmark
    );
    setBookmarks(updatedBookmarks);
  };

  const handleArticleClick = (articleId: string) => {
    navigate(`/user/${currentUser.id}/articles/${articleId}`);
  };

  const updateArticleData = (newData: Partial<Articles>) => {
    setBookmarkArticles((prevPost: any) => ({
      ...prevPost,
      ...newData
    }));
  };
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ewé - Bookmarks</title>
      </Helmet>
      <Box
        paddingTop="20px"
        paddingBottom="20px"
        marginRight={{ base: '15', md: '20' }}
        marginLeft={{ base: '15', md: '20' }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeIn' }}
          style={{ background: colorMode === 'light' ? '#E0EAE0' : '#588552' }}
        >
          <div style={{ overflowY: 'auto' }}>
          <Box
              paddingTop="20px"
              paddingBottom="20px"
            >
              {bookmarksError ? (
                <Error />
              ) : (
                <Box>
                  {bookmarksLoading ? (
                    <Loading />
                  ) : (
                    <Box>
                      <AutoBackButton/>

                      <AutoBackButton />
                    <Text
                      fontSize={{ base: '20px', md: '30px', lg: '40px' }}
                      fontWeight="600"
                      fontFamily="Inter"
                      letterSpacing={3}
                      textAlign="center"
                      color={colorMode === 'light' ? '#345430' : 'white'}
                      paddingTop="40px"
                      paddingBottom="20px"
                    >
                    {language === 'en' ? 'Bookmarks' : (language === 'yo' ? 'fí pamọ́' : 'Marcadores')}
                    </Text>

                      <BookmarkedPosts
                        bookmarks={bookmarks}
                        handlePostCardClick={handlePostCardClick}
                        currentUser={currentUser}
                        isAuthenticated={isAuthenticated}
                        isLoading={bookmarksLoading}
                        updatedPostData={updatePostData}
                      />
                      <BookmarkedArticles
                        bookmarkArticles={bookmarkArticles}
                        handleArticleClick={handleArticleClick}
                        isAuthenticated={isAuthenticated}
                        updatedArticleData={updateArticleData}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </div>
        </motion.div>
      </Box>
    </motion.div>
  );
};

export default Bookmarks;
