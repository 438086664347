import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  useColorMode,
  Text,
} from '@chakra-ui/react';
import {
  BiLike,
  BiDislike
} from 'react-icons/bi';
import { FaComment } from 'react-icons/fa';
import ShareButton from '../ShareButton';
import { BsFillShareFill } from 'react-icons/bs';
import { useLikePostMutation, useDislikePostMutation } from '../../api/postApi'; // Import your API functions
import { useParams, useNavigate } from 'react-router-dom';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { Post, User } from '../../types/interface';
import { useSelector } from 'react-redux';
import ToastNotification from '../ToastNotification';

interface PostButtonsProps {
  post?: Post;
  updatedPostData: any;
}

const PostButtons: React.FC<PostButtonsProps> = ({ post, updatedPostData }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { colorMode } = useColorMode();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = userId || currentUser?.id;
  const navigate = useNavigate(); // Add useNavigate hook
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  const toggleShareModal = () => {
    setIsShareModalOpen(prev => !prev);
  };


  const [likePostMutation] = useLikePostMutation();
  const [dislikePostMutation] = useDislikePostMutation();

  const handleLike = () => {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      return navigate("/login");
    }
  
    if (!post?._id) return;

    const currentLikes = parseInt(post.likes) || 0;
    likePostMutation({ userId: validUserId, postId: post._id })
      .then((response: any) => {
        if ('data' in response) {
          const updatedPost = { ...post, likes: (currentLikes + 1).toString() };
          updatedPostData(post._id, updatedPost);
          // Show success toast
          setToastConfig({ description: 'Post liked successfully', status: 'success' });
          setShowToast(true);
        } else {
          // Show error toast
          setToastConfig({ description: 'Failed to like post', status: 'error' });
          setShowToast(true);
        }
      })
      .catch((error) => {
        console.error('Error liking post:', error);
        // Show error toast
        setToastConfig({ description: 'Failed to like post', status: 'error' });
        setShowToast(true);
      });
  };

  const handleDislike = () => {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      return navigate("/login");
    }
    
    if (!post?._id) return;
    const currentDislikes = parseInt(post.dislikes) || 0;
    dislikePostMutation({ userId: validUserId, postId: post._id })
      .then((response) => {        
        if ('data' in response) {
          const updatedPost = { ...post, dislikes: (currentDislikes + 1).toString() };
          updatedPostData(post._id, updatedPost);
          // Show success toast
          setToastConfig({ description: 'Post Disliked successfully', status: 'success' });
          setShowToast(true);
        } else {
          // Show error toast
          setToastConfig({ description: 'Failed to dislike post', status: 'error' });
          setShowToast(true);
        }
      })
      .catch((error) => {
        console.error('Error liking post:', error);
        // Show error toast
        setToastConfig({ description: 'Failed to like post', status: 'error' });
        setShowToast(true);
      });
  };

  const viewComments = () => {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      return navigate("/login");
    }
    
    navigate(`/user/${validUserId}/posts/${post?._id}`);
  };
  
  return (
    <VStack
      display={'flex'}
      flexDirection={'row'}
      paddingTop={['5px', '10px']}
      paddingBottom={['5px', '10px']}
      gap={10}
    >
      <>
        <Button
          variant="ghost"
          color={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<BiLike fontSize={20} color={colorMode === 'light' ? '#345430' :  'white'} />}
          onClick={handleLike}
        >
          <Text fontSize={16} mr={2}>{post?.likes?.length}</Text>
        </Button>
        <Button
          variant="ghost"
          color={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<BiDislike fontSize={20}  color={colorMode === 'light' ? '#345430' :  'white'} />}
          onClick={handleDislike}
          marginLeft={'-10px'}
        >
          <Text fontSize={16} mr={2}>{post?.dislikes?.length}</Text>
        </Button>
        <Button
          variant="ghost"
          color={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<FaComment fontSize={20} color={colorMode === 'light' ? '#345430' :  'white'} />}
          onClick={viewComments}
        >
          <Text fontSize={16} mr={2}>{post?.comments?.length}</Text>
        </Button>
      </>
       <Box
        textAlign={'start'}
        mt={5}
        mb={5}
        flex={['1', '1', 'auto']}
        position="relative"
        display="flex"
        justifyContent={'flex-start'}
      >
        <Button
          variant="ghost"
          color={colorMode === 'light' ? 'white' :  '#345430'}
          backgroundColor={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<BsFillShareFill fontSize={20} color={colorMode === 'light' ? 'white' :  '#345430'} />}
          onClick={toggleShareModal}
        >
          Share
        </Button>
        {isShareModalOpen && <ShareButton 
        postUrl={`https://www.ewe.com.ng/user/${validUserId}/posts/${post?._id}`} 
        onClose={toggleShareModal}
        />}
      </Box>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </VStack>
  );
};

export default PostButtons;
