import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout, PostAndQuestionSlider, SlideTransitionContainer } from './components';
import { 
  About,
  Bookmarks,
  Contact, 
  CreatePost, 
  Donate, 
  ErrorPage, 
  ForgotPassword,
  Home, 
  Login, 
  PostById, 
  Posts, 
  Project, 
  ResetPassword, 
  SearchPage, 
  Shop, 
  SignUp, 
  UserProfile, 
  UserProfileSettings, 
  ArticleById,
  Article,
  CreateArticle,
  EditPost,
} from './pages';
import { useEffect } from 'react';
import CreateQuestion from './pages/CreateQuestion';
import Questions from './components/Questions/Questions';
import VerifyEmail from './pages/VerifyEmail';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router basename='/'>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="donate" element={<Donate />} />
          <Route path="contact" element={<Contact />} />
          <Route path="project" element={<Project title={''} content={''} image={''} />} />
          <Route path="profile" element={<UserProfile posts={[]} />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="resetpassword/:tokenId/:userId" element={<ResetPassword />} />
          <Route path="shop" element={<Shop />} />
          <Route path="search" element={<SearchPage />} />

          <Route path="user">
            <Route path=":userId">
              <Route index element={<UserProfile posts={[]} />} />
              <Route path="settings" element={<UserProfileSettings />} />
              <Route path="bookmarks" element={<Bookmarks />} />
              <Route path="posts" element={
                <PostAndQuestionSlider>
                  <Posts />
                  </PostAndQuestionSlider>} />
                  <Route path="questions" element={
                <PostAndQuestionSlider>
                  <Questions />
                  </PostAndQuestionSlider>} />
              <Route path="articles" element={<Article />} />
              <Route path="articles/:articleId" element={<ArticleById />} />
              <Route path="posts/:postId" element={<PostById />} />
              <Route path="edit-post/:postId" element={<EditPost />} />
              <Route path="create-post" 
                element={
                  <SlideTransitionContainer>
                    <CreatePost />
                  </SlideTransitionContainer>
                } 
              />
              <Route path="create-question"
                element={
                  <SlideTransitionContainer>
                    <CreateQuestion />
                  </SlideTransitionContainer>
                } 
              />
              <Route path="create-article" element={<CreateArticle />} />
            </Route>
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
